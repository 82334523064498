<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    labels: {
      type: Array,
      required: true
    },
    title: {
      type: String
    },
    data: {
      type: Array,
      required: true
    }
  },
  watch: {
    data() {
      this.render();
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.data
        },
        {
          responsive: true,
          legend: {
            display: true,
            position: "bottom",
            maxWidth: 100,
            onClick: null
          },

          layout: {
            padding: 40
          },
          maintainAspectRatio: false,

          // title: {
          //   position: 'top',
          //   display: true,
          //   text: "Динамика регистрации пользователей (с начала квартала)",
          //   fontSize: 20,
          // },
          tooltips: {
            enabled: false
          },
          plugins: {
            labels: [
              {
                // fontColor: ['green', 'white', 'red'],
                render: "value",
                fontSize: 16,
                precision: 2,
                position: "default"
              }
            ]
          }
        }
      );
    }
  }
};
</script>
