var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',[(_vm.isDateLoaded)?_c('div',{staticClass:"widget"},[_c('ChartLayout',{attrs:{"title":"Общее количество пользователей","needReload":_vm.needDateReload.user},scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('ValidationSelectField',{attrs:{"items":_vm.periodList,"label":"Период","validate-name":"период"},model:{value:(_vm.user_period),callback:function ($$v) {_vm.user_period=$$v},expression:"user_period"}})]},proxy:true},{key:"widget",fn:function(){return [_c('PieCharts',{attrs:{"backgroundColor":['rgb(39,45,83)', 'rgb(149,194,61)'],"labels":['Зарегистрированных', 'Незарегистрированных'],"data":_vm.user_count}})]},proxy:true}],null,false,127364474)}),_c('ChartLayout',{attrs:{"title":"Динамика регистрации пользователей","needReload":_vm.needDateReload.user},scopedSlots:_vm._u([{key:"widget",fn:function(){return [_c('MixedCharts',{attrs:{"labels":_vm.user_labels,"data":[
            {
              type: 'bar',
              label: 'Зарегистрированных',
              data: _vm.user_dates.registered,
              fill: true,
              borderColor: 'rgb(54, 133, 235)',
              backgroundColor: 'rgb(149,194,61)',
              color: 'rgb(255,255,255)',
              order: 0
            },
            {
              type: 'bar',
              label: 'Незарегистрированных',
              data: _vm.user_dates.unconfirmed,
              fill: true,
              borderColor: 'rgb(54, 162, 235)',
              backgroundColor: 'rgb(39,45,83)',
              color: 'rgb(255,255,255)',
              order: 1
            }
          ]}})]},proxy:true}],null,false,3861182056)}),_c('ChartLayout',{attrs:{"title":"Информация по платежам","needReload":_vm.needDateReload.payment},scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('ValidationSelectField',{attrs:{"items":_vm.periodList,"label":"Период","validate-name":"период"},model:{value:(_vm.payments_period),callback:function ($$v) {_vm.payments_period=$$v},expression:"payments_period"}})]},proxy:true},{key:"widget",fn:function(){return [_c('div',{staticStyle:{"display":"grid","grid-template-columns":"repeat(auto-fill, minmax(30em, 1fr))","grid-gap":"30px"}},[_c('PieCharts',{attrs:{"backgroundColor":_vm.backgroundColor,"labels":_vm.payments_labels,"title":'Платежей: ' + _vm.payments.total_completed_transaction,"data":_vm.payments_dates.completed_transaction,"legend-position":'right'}}),_c('PieCharts',{attrs:{"backgroundColor":_vm.backgroundColor,"labels":_vm.payments_labels,"title":'Cумма: ' + _vm.payments.total_income + ' руб.',"data":_vm.payments_dates.completed_income,"legend-position":'right'}})],1)]},proxy:true}],null,false,775341499)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }