<template>
  <!-- v-if="isDateLoaded" -->
  <div class="widget">
    <div class="wrap-widget">
      <div class="wrap-widget__inner" v-if="!needReload">
        <div class="widget-title">
          {{ title }}
        </div>
        <div class="widget-inputs">
          <slot name="inputs"></slot>
        </div>
        <div class="widget-charts">
          <slot name="widget"></slot>
        </div>
      </div>
      <div class="text-center" v-else>
        <v-progress-circular
          :size="160"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartLayout",
  props: {
    title: {
      type: String
      // default: "Виджет"
    },
    needReload: {
      type: Boolean,
      required: true
    },
    widgetGrid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  components: {},

  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {}
};
</script>

<style scoped>
.wrap-widget {
  padding: 5px 10px 5px 10px;
  background-color: #f5f5f5;
}

.wrap-widget__inner {
  background-color: white;
  border-radius: 10px;
  padding: 30px 20px;
}

.widget-title {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  color: #515a6e;
  font-family: Inter, sans-serif;
  font-weight: 700;
  padding: 10px 0;
}

.wrap-widget .widget-inputs {
  display: grid;
  padding: 20px 20px 20px 26px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 14px;
}

.widget-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
