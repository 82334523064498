<script>
import { Pie } from "vue-chartjs";
import "chartjs-plugin-labels";

export default {
  extends: Pie,
  props: {
    backgroundColor: {
      type: Array
    },
    labels: {
      type: Array
    },
    title: {
      type: String
    },
    data: {
      type: Array
    },
    legendPosition: {
      type: String,
      default: "top"
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: this.backgroundColor
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,

          title: {
            display: this.title ? true : false,
            text: this.title,
            fontSize: 14
          },
          legend: {
            display: true,
            onClick: null,
            position: this.legendPosition
          },
          tooltips: {
            enabled: false
          },
          plugins: {
            labels: [
              {
                render: "value",
                fontColor: "white",
                fontSize: 16,
                precision: 2
              }
            ]
          }
        }
      );
    }
  }
};
</script>
