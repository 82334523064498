<template>
  <MainLayout>
    <div v-if="isDateLoaded" class="widget">
      <!-- Общее количество пользователей -->

      <ChartLayout
        title="Общее количество пользователей"
        :needReload="needDateReload.user"
      >
        <template v-slot:inputs>
          <ValidationSelectField
            :items="periodList"
            label="Период"
            v-model="user_period"
            validate-name="период"
          />
        </template>
        <template v-slot:widget>
          <PieCharts
            :backgroundColor="['rgb(39,45,83)', 'rgb(149,194,61)']"
            :labels="['Зарегистрированных', 'Незарегистрированных']"
            :data="user_count"
          />
        </template>
      </ChartLayout>
      <!-- /Общее количество пользователей -->
      <!-- Динамика регистрации пользователей -->
      <ChartLayout
        title="Динамика регистрации пользователей"
        :needReload="needDateReload.user"
      >
        <template v-slot:widget>
          <MixedCharts
            :labels="user_labels"
            :data="[
              {
                type: 'bar',
                label: 'Зарегистрированных',
                data: user_dates.registered,
                fill: true,
                borderColor: 'rgb(54, 133, 235)',
                backgroundColor: 'rgb(149,194,61)',
                color: 'rgb(255,255,255)',
                order: 0
              },
              {
                type: 'bar',
                label: 'Незарегистрированных',
                data: user_dates.unconfirmed,
                fill: true,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgb(39,45,83)',
                color: 'rgb(255,255,255)',
                order: 1
              }
            ]"
          />
        </template>
      </ChartLayout>
      <!-- /Динамика регистрации пользователей -->
      <!-- Информация по платежам -->
      <ChartLayout
        title="Информация по платежам"
        :needReload="needDateReload.payment"
      >
        <template v-slot:inputs>
          <ValidationSelectField
            :items="periodList"
            label="Период"
            v-model="payments_period"
            validate-name="период"
          />
        </template>

        <template v-slot:widget>
          <div
            class=""
            style="display: grid; grid-template-columns: repeat(auto-fill, minmax(30em, 1fr)); grid-gap: 30px"
          >
            <PieCharts
              :backgroundColor="backgroundColor"
              :labels="payments_labels"
              :title="'Платежей: ' + payments.total_completed_transaction"
              :data="payments_dates.completed_transaction"
              :legend-position="'right'"
            />
            <PieCharts
              :backgroundColor="backgroundColor"
              :labels="payments_labels"
              :title="'Cумма: ' + payments.total_income + ' руб.'"
              :data="payments_dates.completed_income"
              :legend-position="'right'"
            />
          </div>
        </template>
      </ChartLayout>
      <!-- /Информация по платежам -->
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import ChartLayout from "@/components/Charts/ChartLayout";
import PieCharts from "@/components/Charts/Pie";
import MixedCharts from "@/components/Charts/Mixed";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import moment from "moment";

export default {
  name: "Widgets",
  data() {
    return {
      isDateLoaded: false,
      backgroundColor: [],
      diller: null,
      organization: null,
      needDateReload: {
        user: false,
        payment: false
      }
    };
  },
  components: {
    MainLayout,
    PieCharts,
    MixedCharts,
    ValidationSelectField,
    ChartLayout
  },

  watch: {
    user_period() {
      this.needDateReload.user = true;
      this.$store.dispatch("fetchUserCountWidgetPage").finally(() => {
        this.needDateReload.user = false;
      });
    },
    payments_period() {
      this.needDateReload.payment = true;
      this.$store.dispatch("fetchPaymentsWidgetPage").finally(() => {
        this.needDateReload.payment = false;
      });
    }
  },
  computed: {
    momentFormat() {
      return this.periodList.filter(el => el.value == this.user_period)[0]
        .moment;
    },
    user_count() {
      return [
        this.$store.getters.getUsersCount.total_registered,
        this.$store.getters.getUsersCount.total_unconfirmed
      ];
    },
    user_period: {
      get() {
        return this.$store.getters.getStatPeriod.user;
      },
      set(newValue) {
        this.$store.commit("SET_STAT_DEFAULT_PERIOD", {
          fieldName: "user",
          payload: newValue
        });
      }
    },
    periodList() {
      return this.$store.getters.getStatPeriodList;
    },
    user_labels() {
      const today = moment().format(this.momentFormat); //.Date.now();
      let tempArr = this.$store.getters.getUsersCount.period.map(el =>
        moment(el.date * 1000).format(this.momentFormat)
      );
      if (this.user_period == "month") {
        tempArr = tempArr.filter(el => Number(el) <= Number(today));
      }
      return tempArr;
    },
    user_dates() {
      return {
        registered: this.$store.getters.getUsersCount.period
          .map(el => el.registered)
          .slice(0, this.user_labels.length),
        unconfirmed: this.$store.getters.getUsersCount.period
          .map(el => el.unconfirmed)
          .slice(0, this.user_labels.length),
        all: this.$store.getters.getUsersCount.period
          .map(el => Number(el.unconfirmed) + Number(el.registered))
          .slice(0, this.user_labels.length)
      };
    },
    payments() {
      return this.$store.getters.getStatPayments;
    },
    payments_labels() {
      const tempArr = this.payments.dealers.map(el => el.dealer.name);
      return tempArr;
    },
    payments_dates() {
      return {
        completed_income: this.$store.getters.getStatPayments.dealers.map(
          el => el.completed_income
        ),
        completed_transaction: this.$store.getters.getStatPayments.dealers.map(
          el => el.completed_transaction
        )
      };
    },
    payments_period: {
      get() {
        return this.$store.getters.getStatPeriod.payments;
      },
      set(newValue) {
        this.$store.commit("SET_STAT_DEFAULT_PERIOD", {
          fieldName: "payments",
          payload: newValue
        });
      }
    }
  },
  mounted() {},
  created() {
    this.generateColors();
    this.$store.dispatch("fetchPaymentsWidgetPage").then(() => {
      this.$store.dispatch("fetchUserCountWidgetPage").finally(() => {
        this.isDateLoaded = true;
      });
    });
  },
  methods: {
    dynamicColors: function() {
      var r = Math.floor(Math.random() * 255);
      var g = Math.floor(Math.random() * 255);
      var b = Math.floor(Math.random() * 255);
      this.backgroundColor.push("rgb(" + r + "," + g + "," + b + ")");
    },

    generateColors() {
      for (let i = 0; i < 30; i++) {
        this.dynamicColors();
      }
    }
  }
};
</script>

<style scoped></style>
